/* eslint no-undef: 0 */

import { z } from 'zod';
import { createEnv } from '@t3-oss/env-nextjs';

export const env = createEnv({
  server: {
    CRON_SECRET: z.string().min(1),
    OPENAI_API_KEY: z.string().min(1),
    POSTGRES_URL: z.string().url(),
    POSTGRES_MIGRATION_URL: z.string().optional(),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    DB_PROVIDER: z.enum(['HTTP', 'NEON', 'VERCEL']),
    NEXTAUTH_SECRET: z.string().min(1),
    FIREBASE_OAUTH2_CLIENT_ID: z.string().min(1),
    FIREBASE_OAUTH2_CLIENT_SECRET: z.string().min(1),
    FIREBASE_OAUTH2_REDIRECT_PATH: z.string().min(1),
    GCP_PROJECT_ID: z.string().min(1),
    GCP_KEY_FILE: z.preprocess(
      (base64String) => {
        const decodedString = Buffer.from(base64String, 'base64').toString(
          'ascii'
        );
        const jsonObject = JSON.parse(decodedString);
        return jsonObject;
      },
      z.object({
        type: z.string().min(1),
        project_id: z.string().min(1),
        private_key_id: z.string().min(1),
        private_key: z.string().min(1),
        client_email: z.string().min(1),
        client_id: z.string().min(1),
        auth_uri: z.string().min(1),
        token_uri: z.string().min(1),
        auth_provider_x509_cert_url: z.string().min(1),
        client_x509_cert_url: z.string().min(1),
        universe_domain: z.string().min(1),
      })
    ),
    PROTOCOL: z.string().min(1),
    VERCEL_URL: z.string().min(1),
    SENTRY_AUTH_TOKEN: z.string().min(1),

  },

  client: {
    NEXT_PUBLIC_NODE_ENV: z.enum(['development', 'test', 'production']),
    NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),
    NEXT_PUBLIC_VERCEL_URL_PROTOCOL: z.preprocess((str) => {
      // Check if NEXT_PUBLIC_VERCEL_URL is a valid URL, then prepend 'https://'
      if (process.env.NEXT_PUBLIC_VERCEL_URL_PROTOCOL) {
        return process.env.NEXT_PUBLIC_VERCEL_URL_PROTOCOL;
      }
      if (process.env.DOMAIN) {
        return `${PROTOCOL}${process.env.DOMAIN}`;
      } else if (process.env.NEXT_PUBLIC_VERCEL_URL) {
        return `${PROTOCOL}${process.env.NEXT_PUBLIC_VERCEL_URL}`;
      }
      return str;
    }, z.string().url()), // This is where the full URL with protocol is validated
  },

  runtimeEnv: {
    CRON_SECRET: process.env.CRON_SECRET,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    POSTGRES_URL: process.env.POSTGRES_URL,
    POSTGRES_MIGRATION_URL: process.env.POSTGRES_MIGRATION_URL,
    NODE_ENV: process.env.NODE_ENV,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    DB_PROVIDER: process.env.DB_PROVIDER,
    PROTOCOL: process.env.PROTOCOL,
    VERCEL_URL: process.env.VERCEL_URL,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_VERCEL_URL_PROTOCOL:
      process.env.NEXT_PUBLIC_VERCEL_URL_PROTOCOL,
    FIREBASE_OAUTH2_CLIENT_ID: process.env.FIREBASE_OAUTH2_CLIENT_ID,
    FIREBASE_OAUTH2_CLIENT_SECRET: process.env.FIREBASE_OAUTH2_CLIENT_SECRET,
    FIREBASE_OAUTH2_REDIRECT_PATH: process.env.FIREBASE_OAUTH2_REDIRECT_PATH,
    GCP_PROJECT_ID: process.env.GCP_PROJECT_ID,
    GCP_KEY_FILE: process.env.GCP_KEY_FILE,
  },

  skipValidation: process.env.SKIP_ENV_VALIDATION === 'true',
});
